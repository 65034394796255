var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exmItem" },
    [
      _c("div", { staticClass: "e_name" }, [_vm._v(_vm._s(_vm.auditName))]),
      _c(
        "div",
        [
          _c("TForm", {
            ref: "refFrom",
            attrs: {
              autoCheck: true,
              model: _vm.htmlDatas,
              formlist: _vm.formlist,
              rowGutter: 16,
            },
            scopedSlots: _vm._u([
              {
                key: "orderCode",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "t_row" }, [
                      _c("div", { staticClass: "tr_name" }, [
                        _vm._v("工单编号 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "tr_val" },
                        [
                          _c("span", { attrs: { id: "copycode" } }, [
                            _vm._v(_vm._s(_vm.htmlDatas[scope.current.keys])),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticClass: "txBlue",
                              attrs: { type: "text" },
                              on: { click: _vm.copyCode },
                            },
                            [
                              _c("i", { staticClass: "el-icon-copy-document" }),
                              _vm._v("复制"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "e_btns" },
        [
          _vm.showBtnByType("passReject")
            ? [
                _c(
                  "el-button",
                  { staticClass: "b_pass", on: { click: _vm.passHandler } },
                  [_vm._v("通过")]
                ),
                _c(
                  "el-button",
                  { staticClass: "b_reject", on: { click: _vm.openRejectPup } },
                  [_vm._v("驳回")]
                ),
              ]
            : _vm._e(),
          _vm.showBtnByType("revoke")
            ? _c(
                "el-button",
                { staticClass: "b_cancel", on: { click: _vm.revokeByTypes } },
                [_vm._v("撤销")]
              )
            : _vm._e(),
          _vm.showBtnByType("sumbit")
            ? _c(
                "el-button",
                { staticClass: "b_cancel", on: { click: _vm.againSubmit } },
                [_vm._v("重新提交")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "diyModel",
          attrs: {
            title: "驳回申请",
            visible: _vm.rejectObj.open,
            "close-on-click-modal": false,
            width: "728px",
            "before-close": _vm.closeMypup,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.rejectObj, "open", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("TForm", {
                ref: "rejectlFrom",
                attrs: {
                  lableRow: "",
                  autoCheck: true,
                  labelPosition: "left",
                  model: _vm.rejectObj.datas,
                  formlist: _vm.formlist2,
                  "label-width": "88px",
                  rowGutter: 16,
                },
              }),
              _c(
                "div",
                { staticClass: "btmBtn" },
                [
                  _c(
                    "el-button",
                    { staticClass: "searchbtn", on: { click: _vm.closeMypup } },
                    [_vm._v(" 取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.validateFroms },
                    },
                    [_vm._v(" 提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }