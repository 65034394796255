<template>
	<!--订单详情-->
	<div class="oDetail" v-loading="detailLoading">
		<div class="evbtns">
			<div class="it_btn" v-for='(it,dex) in operatBtns' :class="it.type" :key='dex' @click.stop='openPopupByTtpe(it)' ><img :src="it.icon" class="_icon" /> {{it.name}}</div>
		</div>
		<div class="checkInfo" >
			<div v-for="(it,dex) in examineArrs" :key='dex'>
				<examineType  :datas='it' @resetOrder='initHtml'></examineType>
			</div>
		</div>
		<div class="detailMain">
			<div class="top_des txNowrap">
				<span class="typelabel">{{common.getOrderStatus(orderInfo.orderStatus)}}</span>{{orderInfo.contentDesc}}
			</div>
			<div class="statusLine">
				<div class="l_it" v-for="(it,dex) in lineDatas.Arr" :key='dex' :class="{isOver:dex<=(lineDatas.val-1)}">
					<div class="s_name"  :class="{isNow:dex==(lineDatas.val)}">
						<img class="s_icon" v-if="dex<=(lineDatas.val-1)" src="@/assets/images/order/green1.png"/>
						<span class="s_round"  v-else></span>
						<span >{{it.processTypeName}}</span>	
						</div>
					<div class="d_its">
						<ul >
							<li class="itflex" v-if="i<it.showIts" v-for="(_d,i) in it.orderDynamicVOS" :key='i'>
								<div class="txNowrap">{{_d.creationDateStr}}</div>
								<div class="txNowrap">{{_d.userName}}</div>
								<div class="txNowrap">{{_d.operationName}}</div>
								<div class="cause" v-if='_d.explainCard'>
										驳回原因：{{_d.explainCard}}
									</div>
								
							</li>
							<li v-if="it.maxlen>2">
								<el-button v-if='it.showIts<3' type="text" class='txGray' @click='it.showIts=it.orderDynamicVOS.length'>展开  <i class="el-icon-arrow-down"></i></el-button>
								<el-button v-else type="text" class='txGray' @click='it.showIts=2'>收起  <i class="el-icon-arrow-up"></i></el-button>
							</li>
							
							<!--<li v-if="it.orderDynamicVOS&&it.orderDynamicVOS.length<1">暂未开始</li>-->
						</ul>
					</div>
				</div>
			</div>
			<div>
				<orderInfo type='1' :datas='orderInfo'></orderInfo>
				<orderInfo type='2' :datas='orderInfo'></orderInfo>
			</div>
			<div v-if="orderInfo.orderPaidServiceVO">
				<paidSeiveInfo :datas='orderInfo.orderPaidServiceVO'></paidSeiveInfo>
			</div>
			<div v-if="orderInfo.source==2">
				<orderInfo type='3' :datas='orderInfo'></orderInfo>
				<orderInfo type='4' :datas='orderInfo'></orderInfo>
			</div>
			<div v-if="orderInfo.orderMaterielSonList&&orderInfo.orderMaterielSonList.length > 0">
				<matterRecord :datas='orderInfo'></matterRecord>
			</div>
		</div>
		<operatePopup :isOpen='exPopInfo.open' :type='exPopInfo.type' :datas='orderInfo' @close='closeDialog' @resetDetail='initHtml'></operatePopup>
	</div>
	
</template>

<script>
	import { getOrderDetail } from "@/api/business/order/tenant/order";
	import handsRlink from "@/mixins/handsRlink.js";
	import TForm from '@/components/YTable/TForm.vue';
	import examineType from "./model/examineType.vue";
	import operatePopup from "./model/operatePopup.vue";
	import orderInfo from "./model/orderInfo.vue";
	import paidSeiveInfo from "./model/paidSeiveInfo.vue";
	import matterRecord from "./model/matterRecord.vue";
	export default {
		name: "OrderDetail",
		components: {TForm,examineType,orderInfo,matterRecord,operatePopup,paidSeiveInfo},
		mixins: [handsRlink],
		props: {},
		data() {
			return {
				detailLoading:false,
				operatBtns:[],
				operatObjs:{
					//这个对象是前端与后台对应的数据   其中val是前端定义 关联对应打开的弹窗
					1:{name:'领取',val:'receive',type:'',icon:require('@/assets/images/order/b_lq.png')},
					2:{name:'转派',val:'reassignment',type:'',icon:require('@/assets/images/order/b_zp.png')},
					3:{name:'协助',val:'assist',type:'',icon:require('@/assets/images/order/b_xz.png')},
					4:{name:'领料',val:'drawMatter',type:'',icon:require('@/assets/images/order/b_ll.png')},
					5:{name:'延时',val:'delayed',type:'',icon:require('@/assets/images/order/b_ys.png')},
					6:{name:'关单',val:'closeOrder',type:'',icon:require('@/assets/images/order/b_gd.png')},
					7:{name:'完成',val:'finishOrder',type:'primary',icon:require('@/assets/images/order/wancheng.png')},
					8:{name:'去执行',val:'toExecute',type:'',icon:require('@/assets/images/order/b_zx.png')},
					9:{name:'去签到',val:'toSign',type:'',icon:require('@/assets/images/order/b_qd.png')},
				},
				orderId:'',
				auditType:'',
				orderInfo:{
					contentDesc:'',
					
				},//记录存储工单详情信息
				isExecutingClose:false,//判断是否执行中进行关单
				alarmVoDetail:'',
				examineArrs:[],
				exPopInfo:{
					open:false,
					type:'reassignment',
				},
				lineDatas:{
					val:1,
					Arr:[],//item={name:'报单',detail:[]}
				},
				
			};
		},
		computed: {
		    orderEditOrSumit() {
		      	return this.$store.state.app.orderUpdate;
		    },
	  	},
		watch:{
			orderEditOrSumit: {
		      	deep: true,
		      	handler(v, n) {
		      		let pupType=this.$store.state.app.orderAddEdit.type;
		      		console.log('-------',pupType)
		      		if(pupType=='againAdd'){
		      			this.$router.go(-1);
		      		}else{
		      			this.initHtml();
		      		}
		        	
		      	},
		    },
			
		},
		created() {
			const {orderId,auditType}=this.$route.query;
			console.log('orderId',orderId)
			if(orderId){
				this.orderId=orderId;
			}
			if(auditType){
				this.auditType=auditType;
			}
//			this.$store.dispatch('getEmployeeDatas');//获取部门人员数据
			this.$store.dispatch('getAllDevices');//获取部门人员数据
			this.initHtml();
		},
		mounted() {},
		methods: {
			
			initHtml(){
				this.getDetailById();
			},
			
			
			initOperatBtns(Arrs){
				this.operatBtns=[];
				if(!Arrs) return;
				//BUTTON_RECEIVE("领取",1),BUTTON_TRANSFER("转派",2),BUTTON_ASSIST("协助",3),BUTTON_MATERIEL("领料",4),
				//BUTTON_DELAYED("延时",5),BUTTON_CLOSE("关单",6),BUTTON_COMPLETE("完成",7),BUTTON_EXECUTE("去执行",8),
				//BUTTON_SIGN("去签到",9)
				//后台过来的数据  [{type: 3, typeName: "协助", show: true}] 需要与前端进行匹配
				let fArr=[];
				Arrs.map(it=>{
					if(it.show&&it.type){
						let itBtn=this.operatObjs[Number(it.type)];
						if(itBtn) fArr.push(itBtn);
					}
				})
//				for(let keys in this.operatObjs){
//					fArr.push(this.operatObjs[keys])
//				}
				this.operatBtns=fArr;
				//最终得到前端需要的数据   其中val是前端需要的数据
//				operatBtns=[
//					{name:'完成',val:'finishOrder',icon:'xxx',type:'primary'},
//				];
			},
			
			async	getDetailById(){
				//详情
		      	this.detailLoading = true;
		      	let params={
		      		orderId:this.orderId,
		      		isloading:false,
		      	}
		     	let res = await this.ApiHttp('/order/tenant/order/getDetails',params);
		     	this.detailLoading = false;
		      	if(res){
//		      		this.orderInfo = res.orderCurrencyVO;
		      		if(res.orderCurrencyVO){
		      			//工单信息
		      			let orderData=res.orderCurrencyVO;
		      			if(orderData.orderLevel!==null){
		      				let txObj={
		      					0:'普通',
		      					1:'紧急',
		      					2:'告警',
		      				}
		      				orderData.orderLevelText=txObj[orderData.orderLevel]
		      			}
		      			orderData.auditType=this.auditType;
		      			orderData.submitUserNamePhone=orderData.submitUserName;
		      			if(orderData.submitUserPhone){
		      				//报单人名+电话
		      				orderData.submitUserNamePhone+='-'+orderData.submitUserPhone;
		      			}
		      			orderData.handlePersonNamePhone=orderData.handlePersonName;
		      			if(orderData.handlePersonName){
		      				//执行人名+电话
		      				orderData.handlePersonNamePhone+='-'+orderData.handlePersonPhone;
		      			}
		      			if(orderData.orderDynamicList){
				          	let findBy=['sign_order','start_implement'];
				          	for(let it of orderData.orderDynamicList){
				          		if(findBy.indexOf(it.operationType)>-1){
				          			this.isExecutingClose=true;
				          			break;
				          		}
				          	}
				          }
			          	orderData.closeDateStr=this.common.getTimeMmss(orderData.closeDate)
			          	if(orderData.alarmVo){
				          	const {alarmVo}=orderData;
				          	if(!alarmVo.propertyDescription) alarmVo.propertyDescription='-';
				          	if(!alarmVo.propertyUnit) alarmVo.propertyUnit='';
				          	let showAlarmValue=alarmVo.alarmValue+' '+alarmVo.propertyUnit;
				          	if(!alarmVo.alarmValue) showAlarmValue='-';
				          	this.alarmVoDetail='告警类型:'+ this.AlarmTypeObj[alarmVo.alarmType]+
				          	'; 告警内容:'+alarmVo.alarmContent+
				          	'; 告警值:'+showAlarmValue+
				          	'; 告警时间:'+this.common.getTimeMmss(alarmVo.alarmTimestamp)+
				          	'; 告警参数:'+alarmVo.propertyDescription
			         	}
			          	if(res.orderProcessVOS){
			          		let exStatus=res.orderProcessVOS[2];
			          		if(exStatus){
			          			let len=exStatus.orderDynamicVOS.length;
			          			if(len>0){
			          				orderData.executeTimeStr=exStatus.orderDynamicVOS[len-1].creationDateStr;
			          			}
			          		}
			          	}
			          	if(res.orderCurrencyVO.orderPaidServiceVO){
			          		res.orderCurrencyVO.orderPaidServiceVO.inPlaceStr=res.orderCurrencyVO.enterpriseName||res.orderCurrencyVO.location;
			          	}
			          	this.orderInfo = orderData;
		      		}
		      		if(res.auditInfoVOS){
		      			//审核信息  原先是单个 后面改成了多个
//		      			this.examineDatas={
//		      				...res.auditInfoVO,
//		      				orderId:this.orderId,
//		      				orderInfo:res.orderCurrencyVO,
//		      			}
		      			let Arr=[];
		      			res.auditInfoVOS.map(item=>{
		      				Arr.push({
		      					...item,
			      				orderId:this.orderId,
			      				orderInfo:res.orderCurrencyVO,
		      				})
		      				
		      			})
		      			this.examineArrs=Arr;
		      			
		      			console.log('999999',this.examineArrs)
		      			
		      		}else{
		      			this.examineArrs=[];
		      		}
		      		
		      		if(res.orderDetailButtonVOS){
		      			//工单允许操作的按钮
		      			this.initOperatBtns(res.orderDetailButtonVOS);
		      		}
		      		if(res.orderProcessVOS){
		      			let nowStep=0;
		      			const statusNum=this.orderInfo.orderStatus;
		      			res.orderProcessVOS.map((it,dex)=>{
		      				it.maxlen=it.orderDynamicVOS.length;
		      				it.showIts=2;
		      				if(it.orderDynamicVOS&&it.maxlen>0){
		      					nowStep=dex;
		      				}
		      			})
		      			if(nowStep<1){
		      				if(this.orderInfo.isSelf=='Y'&&statusNum==2){
		      					//自己做单 没有领单 会直接到待执行
		      					nowStep=2;
		      				}
		      			}
		      			if(statusNum<1){
		      				nowStep=0;
		      			}
		      			if(statusNum==5||statusNum==9){
	      					//已关单或已完成的时候 
	      					nowStep=5;
	      				}
		      			if(statusNum==1){
	      					//待领取
	      					nowStep=1;
	      				}
		      			if(statusNum==2||statusNum==3){
	      					//待执行
	      					nowStep=2;
	      				}
		      			this.lineDatas.val=nowStep;
		      			this.lineDatas.Arr=res.orderProcessVOS;
		      		}
		      		this.$store.dispatch('getEmployeeDatas',{resDeptId:this.orderInfo.resDeptId});//获取部门人员数据
		      	}
			},
			
			async openPopupByTtpe(data){
				let type=data.val;
				if(type=='receive'||type=='toExecute'){
					//领取或者执行
					this.receiveOrExecute(type);
					return;
				}
				if(type=='toSign'){
					this.$message({type:'warning',message:'电脑端暂不支持签到'});
					return;
				}
				
				this.$store.dispatch('getAllDevices',this.orderInfo.projectId);//获取项目下的设备
				this.exPopInfo={
					type:type,
					open:true,
				}
//				console.log('111111',JSON.stringify(this.exPopInfo))
			},
			async receiveOrExecute(type='receive'){
				//领取工单
				let params={
					orderId: this.orderInfo.orderId
				}
				let toUrl='/receiveOrder';
				if(type=='toExecute'){
					toUrl='/implementOrder'
				}
				let res=await this.ApiHttp('/order/tenant/orderFill'+toUrl,params,'PUT');
				if(res){
					this.initHtml();
				}
			},
			closeDialog(){
				this.$store.dispatch('getAllDevices');//获取项目下的设备
				this.exPopInfo.open=false;
			},
			toTable(){
				this.$router.go(-1);
			},
			
			
		},
	};
</script>
<style type="text/css" lang="scss" scoped>
	.oDetail {
		height: calc(100vh - 60px);
		overflow-y: auto;
		box-sizing: border-box;
		padding: 0 24px;
		.evbtns{
			padding-top: 16px;
			.it_btn{
				display: inline-block;
				width: 96px;
				line-height: 40px;
				font-size: 14px;
				color: #4E5969;
				background: white;
				border-radius: 3px;
				text-align: center;
				border: 1px solid #E4E7ED;
				margin-right: 12px;
				cursor: pointer;
			}
			.it_btn.primary{
				color: white;
				background: #1A4CEC;
			}
			.it_btn:hover{
				background: #ecf5ff;
			}
			._icon{
			    vertical-align: sub;
			}
		}
		.checkInfo{
			margin-top: 16px;
		}
		.detailMain{
			background: #FFFFFF;
			border-radius: 4px;
			padding: 8px 32px;
			.top_des{
				font-size: 16px;
				font-weight: bold;
				color: #2F3941;
				padding: 24px 0;
				.typelabel{
					display: inline-block;
					padding: 0 12px;
					height: 27px;
					font-size: 14px;
					font-weight: bold;
					color: #1A4CEC;
					line-height: 27px;
					background: #F4F6FE;
					border-radius: 2px;
					text-align: center;
					margin-right: 16px;
				}
				
			}
			.statusLine{
				display: flex;
				.l_it{
					flex: 1;
					border-top: 2px solid #E0E0E0;
					padding: 15px 0;
					.s_name{
						color: #909399;
						span{
							font-weight: bold;
							font-size: 14px;
						}
						.s_icon,.s_round{
							margin-right: 8px;
						}
						.s_round{
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							line-height: 14px;
							border: 1px dashed #909399;
							font-size: 12px;
							text-align: center;
							vertical-align: top;
						}
					}
					.isNow.s_name{
						
						color: #1A4CEC;
						.s_round{
							border: 1px dashed #1A4CEC;
						}
					}
					.d_its{
						font-size: 13px;
						margin-top: 8px;
						color: #909399;
						line-height: 24px;
						.txGray{
							padding: 0;
							color: #4E5969;
						}
						.itflex{
							.txNowrap{
								display: inline-block;
								vertical-align: middle;
								position: relative;
							}
							.txNowrap:nth-child(1){
								width: 32%;
							}
							.txNowrap:nth-child(2){
								padding:0 8px;
								width: 18%;
							}
							.txNowrap:nth-child(3){
								width: 48%;
							}
							.cause{
									font-size: 12px;
									color: #909399;
									line-height: 16px;
								}
						}
					}
				}
				.l_it.isOver{
					border-top: 2px solid #1A4CEC;
					.s_name{
						color:#000000;
						
						/*.s_round{
							border: 1px solid #55D057;
						}
						.s_round::after{
							content:'✓'
						}			*/
					}
				}
			
			}
		}
	}
</style>
<style type="text/css" lang="scss">

</style>