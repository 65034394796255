<template>
	<!--物料领用记录-->
	<div class="table-body">
		<TTable ref="matterTable" :tbloading='tbloading'  :tableData="tableData"  :tableTitle="tableTitle" >
			<template slot='matterName' slot-scope="scope">
				<el-button type="text" class='txBlue' >LED方形投射灯</el-button>
			</template>
		</TTable>
	</div>
</template>
<script>
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable from '@/components/YTable/TTable.vue';
	export default {
		mixins: [seachAndTable],
		props: {
			datas: {
				type: Object,
				default: ()=>{
					return {
						orderMaterielSonList:[
//							{number:1,materielName:'KG316T',specifications:'个',userName:'黄必源',creationDateStr:'申请时间',deptName:'申请部门'}
						],
					}
				},
			},
		},
		watch:{
			datas:{
				deep:true,
				handler(v,o){
					this.resetTbDatas();
				},
			},
		},
		components: {TTable},
		data() {
			return {
				addModel:{//新增，编辑的弹框信息
      		open:false,
      		editRowId:'',
      	},
			};
		},
		created() {
			this.initTbConfig();
		},
		mounted() {},
		computed: {},
		methods: {
			initTbConfig(){
				const tableTitle = [
					//{name:'物料名称',width:"180",slotName:"matterName",},
					{name:'物料名称',width:"180",prop:"materielName"},
					{name:'数量',prop:"number"},
					{name:'单位',prop:"specifications"},
					{name:'申请人员',prop:"userName"},
					{name:'申请部门',prop:"deptName"},
					{name:'申请时间',prop:"creationDateStr"},
				];
				this.tableTitle = tableTitle;
				this.resetTbDatas();
			},
			resetTbDatas(){
				if(this.datas.orderMaterielSonList&&this.datas.orderMaterielSonList.length>0){
					this.tableData =JSON.parse(JSON.stringify(this.datas.orderMaterielSonList));
				}
				
			},
		},
	};
</script>
<style scoped>
</style>