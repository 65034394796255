<template>
	<!--审核类型详情-->
	<div class="exmItem">
		<div class="e_name">{{auditName}}</div>
		<div >
			<TForm  ref="refFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist"  :rowGutter='16'>
				<template slot="orderCode" slot-scope="scope">
					<div class="t_row">
                    	<div class="tr_name">工单编号 </div>
                    	<div class="tr_val"> <span id="copycode">{{htmlDatas[scope.current.keys]}}</span><el-button type="text" @click='copyCode' class='txBlue'> <i class="el-icon-copy-document"></i>复制</el-button> </div>
                    </div>
				</template>
			</TForm>
		</div>
		<div class="e_btns" >
			<template  v-if="showBtnByType('passReject')">
				<el-button class='b_pass' @click='passHandler'>通过</el-button>
				<el-button class='b_reject' @click='openRejectPup'>驳回</el-button>
			</template>
			<el-button v-if="showBtnByType('revoke')" class='b_cancel' @click='revokeByTypes'>撤销</el-button>
			<el-button v-if="showBtnByType('sumbit')" class='b_cancel' @click='againSubmit'>重新提交</el-button>
		</div>
		
		<el-dialog class='diyModel' title="驳回申请" :visible.sync="rejectObj.open" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div >
				<TForm  ref="rejectlFrom" lableRow :autoCheck='true' labelPosition='left' :model="rejectObj.datas" :formlist="formlist2" label-width='88px' :rowGutter='16'></TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary"  @click='validateFroms'> 提交</el-button>
		        </div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			datas: {
				type: Object,
				default: ()=>{
					return {
						title: "报单申请审核中",
						type: 2,
						orderId:'',
						detail: [],
						orderInfo:{},
					}
				},
			},
		},
		watch:{
			datas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.resetTFrom();
				}
			},
			
		},
		components: {TForm},
		data() {
			return {
				auditName:'审核状态',
				htmlDatas:{},
				formlist:[],
				revokeObjs:{
		    		2:{name:'报单撤销',urls:'/order/tenant/orderFill/revokeOrder',postKey:'orderId'},
		    		7:{name:'验收撤销',urls:'/order/tenant/orderFill/revokeImplementComplete',postKey:'orderId'},
		    		9:{name:'关单撤销',urls:'/order/tenant/orderFill/revokeCloseOrder',postKey:'orderId'},
		    		11:{name:'物料撤销',urls:'/order/tenant/orderMateriel/revokeMateriel',postKey:'orderMaterielId'},
		    		13:{name:'延时撤销',urls:'/order/tenant/orderDelayed/revokeDelayed',postKey:'orderDelayedId'},
		    		15:{name:'转派撤销',urls:'/order/tenant/orderTransfer/revokeTransfer',postKey:'orderTransferId'},
		    		17:{name:'协助撤销',urls:'/order/tenant/orderAssist/revokeAssist',postKey:'orderAssistId'},
		    	},
		    	auditTypes:{
		    		//keys 对应的是卡片审核状态this.datas.type  
		    		//value 1 延时审核 2 协助审核 3转派审核 4 物料审核 5 完成审核 6工单报单审核 7关单审核 是对应的common 的 AuditUrlByType获取对应的接口
		    		1:6,
		    		6:5,
		    		8:7,
		    		10:4,
		    		12:1,
		    		14:3,
		    		16:2,
		    	},
				showBtnsObj:{
					//什么状态先允许显示什么按钮
//					AUDIT_REPORTS_TO_EXAMINE_REVIEWER("请您审批-报单审核", 1), AUDIT_REPORTS_TO_EXAMINE("报单申请审核中", 2),
//					AUDIT_SUB_ORDER_REFUSE("报单被驳回", 3), AUDIT_REVOKE_ORDER("报单已撤销", 4),AUDIT_COMPLETE_ORDER_REFUSE("验收被驳回", 5),
//					AUDIT_TO_BE_AUDITED_REVIEWER("请您审批-验收审核", 6), AUDIT_TO_BE_AUDITED("验收申请审核中", 7),
//					AUDIT_CLOSE_TO_EXAMINE_REVIEWER("请您审批-关单审核", 8),AUDIT_CLOSE_TO_EXAMINE("关单申请审核中", 9),
//					AUDIT_MATERIEL_TO_EXAMINE_REVIEWER("请您审批-物料审核", 10), AUDIT_MATERIEL_TO_EXAMINE("物料申请审核中", 11),
//					AUDIT_DELAYED_TO_EXAMINE_REVIEWER("请您审批-延时审核", 12),AUDIT_DELAYED_TO_EXAMINE("延时申请审核中", 13),
//					AUDIT_TRANSFER_TO_EXAMINE_REVIEWER("请您审批-转派审核", 14),AUDIT_TRANSFER_TO_EXAMINE("转派申请审核中", 15),
//					AUDIT_ASSIST_TO_EXAMINE_REVIEWER("请您审批-协助审核", 16),AUDIT_ASSIST_TO_EXAMINE("协助申请审核中", 17)
					passReject:{
						name:'通过驳回',
						showVals:[1,6,8,10,12,14,16]
					},
					revoke:{
						name:'撤销申请',
						showVals:[2,7,9,11,13,15,17],
					},
					sumbit:{
						name:'重新提交',
						showVals:[3,4],
					},
				},
				formlist2:[
					{name: '驳回原因',keys: 'examineFailReason',value: '',type: 'input',flex:24,isMust:true,maxlen:40},
				],
				rejectObj:{
					open:false,
					datas:{
						examineFailReason:'',
					},
					
				},
				resourceArrs1:[{fileId: 33950,fileType: "image/jpeg",thumbnailId: null,},],
				resourceArrs2:[
					{
						fileId: 31720,
						fileType: "image/jpeg",
						thumbnailId: null,
					},
					{
						fileId: 31721,
						fileType: "video/mp4",
						thumbnailId: 31718,
					},
				
				]
				
			}
		},
		
		computed: {},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			openRejectPup(){
				this.rejectObj.open=true;
			},
			closeMypup(){
				this.rejectObj={
					open:false,
					datas:{
						examineFailReason:'',
					},
				};
			},
			passHandler() {
				//通过
				const auditType=this.auditTypes[this.datas.type];
		      	let params={
		      		orderId:this.datas.orderInfo.orderId,
		      	}
		      	this.doRequest(auditType, params);
		    },
		    
		    validateFroms(){
				let allow=this.$refs.rejectlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.submitRejectHandler();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
		    submitRejectHandler() {
		    	//驳回
		      	let params={
		      		orderId:this.datas.orderInfo.orderId,
//		      		examineFailReason: this.rejectObj.datas.examineFailReason,
		      	}
		      	const auditType=this.auditTypes[this.datas.type];
		      	this.doRequest(auditType,params);
		    },
	     	async doRequest(auditType, params) {
		      	let rqData=this.common.getOrderAuditParams(auditType);
		      	if(!rqData) return;
		      	if(auditType<5){
		      		let setatusRes=await this.getOrderStatusById();
		    		if(setatusRes){
		    			params[rqData.keys]=setatusRes[rqData.keys];
		    		}
		      	}
		      	params[rqData.rejectKey]=this.rejectObj.datas.examineFailReason;
		      	let res=await this.ApiHttp(rqData.urls, params, "put");
		      	if(res){
		      		this.$message.success("操作成功！");
		      		this.$emit('resetOrder')
		      	}
		    },
		    
		    async getOrderStatusById(){
		    	//根据工单状态获取
		    	let bkData;
		    	let res = await this.ApiHttp('/order/tenant/order/getOrderStatus?orderId='+this.datas.orderInfo.orderId);
		    	if(res){
	    			bkData=res;
	    		}
		    	return bkData;
		    },
		    
		   	async revokeByTypes(){
		    	//通过当前状态  执行不同的撤销申请
		    	let exmNum=Number(this.datas.type);//当前审核状态
		    	if(!exmNum) return;
		    	let rkItem=this.revokeObjs[exmNum];
		    	let rsMethod='get';
		    	let byData=JSON.parse(JSON.stringify(this.datas.orderInfo));
		    	if(exmNum>10){
		    		rsMethod='PUT';
//		    		let res = await this.ApiHttp('/order/tenant/order/getOrderStatus?orderId='+this.datas.orderInfo.orderId);
		    		let res=await this.getOrderStatusById();
		    		if(res){
		    			byData=res;
		    		}
		    	}
		    	let params={};
		    	params[rkItem.postKey]=byData[rkItem.postKey];
		    	let rkRes=await this.ApiHttp(rkItem.urls,params,rsMethod);
		    	if(rkRes){
		    		this.$message.success("操作成功！");
		      		this.$emit('resetOrder')
		    	}
		    },
		    againSubmit(){
		    	//重新提交 打开新建工单接口  填入数据
		    	console.log('this.datas.orderInfo',JSON.parse(JSON.stringify(this.datas.orderInfo)))
		    	this.$store.commit('setOrderAddEdit',{
					open:true,
   					type:'againAdd',
   					orderDatas:this.datas.orderInfo,
				});
		    },
			showBtnByType(type){
				let isShow=false;
				//通过判断当前审核状态  判断是否有权限显示对应按钮 具体showVals值代表含义需与后台沟通 5=验收被驳回的时候=无按钮
				let exmNum=Number(this.datas.type);//当前审核状态
				if(type){
					let Arrs=this.showBtnsObj[type].showVals;
					let dex=Arrs.indexOf(exmNum);
					if(dex>-1){
						isShow=true;
					}
				}
				return isShow;
			},
			resetTFrom(){
				if(this.datas.detail){
					let fArr=[];
					this.datas.detail.map((it,dex)=>{
						if(it.name =='标题'){
							this.auditName=it.value;
						}else{
							let itRow={
								name: it.name,
								keys: it.keys,
								value: it.value,
								type: 'txRow',
								flex:12,
							}
							if(it.keys=='des'){
								itRow.flex=24;
								itRow.diyClass='e_des';
								itRow.hideName=true;
							}
							if(it.type=='imgVedios'){
								itRow.type='imgVideo';
								itRow.imgWidth='80px';
								itRow.imgHeight='80px';
//								itRow.value=[{fileId: 33950,fileType: "image/jpeg",thumbnailId: null,}]
							}
							fArr.push(itRow);
						}
					})
					this.formlist=fArr;
				}
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			
		},

	}
</script>

<style lang="scss" scoped>
.exmItem{
	/*min-height: 172px;*/
	padding:12px 24px;
	box-sizing: border-box;
	background: #FFFCF5;
	border-radius: 3px 3px 3px 3px;
	opacity: 1;
	border: 1px solid #F3AF00;
	font-family: Noto Sans SC-Bold, Noto Sans SC;
	margin-bottom: 16px;
	line-height: 20px;
	.e_name{
		font-size: 16px;
		font-weight: bold;
		color: #2F3941;
		padding: 12px 0;
	}
	.sign_info{
		padding: 4px 0 8px 0;
		.tp_name{
			font-size: 12px;
			color: #2F394170;
		}
		
	}
	.e_btns{
		
		.el-button{
			border: 1px solid #F3AF00;
			color: #F3AF00;
			margin: 12px 0;
			min-width: 96px;
			margin-right: 12px;
		}
		.b_pass{
			background: #F3AF00;
			color: #FFFFFF;
		}
	}
}
</style>
<style lang="scss">
	.Y-myFrom  .e_des{
		.t_row{
			line-height: 20px !important;
			padding: 12px 0;
			color: #2F3941;
			.tr_val{
				font-weight: inherit !important;
				font-size: 16px;
			}
		}
		
	}
</style>