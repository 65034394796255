var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-body" },
    [
      _c("TTable", {
        ref: "matterTable",
        attrs: {
          tbloading: _vm.tbloading,
          tableData: _vm.tableData,
          tableTitle: _vm.tableTitle,
        },
        scopedSlots: _vm._u([
          {
            key: "matterName",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  { staticClass: "txBlue", attrs: { type: "text" } },
                  [_vm._v("LED方形投射灯")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }