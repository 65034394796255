<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="popupTitle" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div v-if="pupIsOpen" :key='type'>
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="checkMatter" slot-scope="scope">
					  	<div class="mtWarp">
					  		<el-row class='itMtrow' v-for='(it,dex) in htmlDatas[scope.current.keys]' :key='dex'>
							  	<el-col :span="12">
							  		<el-select v-model="it.materielId" filterable   placeholder="可输入物料名称、编码、型号搜索" >
		                                <el-option v-for="(item,dex2) in materielArrs" :key="dex2" :label="item.materielName" :value="item.materielId"></el-option>
		                            </el-select>
							  	</el-col>
							  	<el-col :span="6" class='inpNum'>
							  		<el-input-number  style='width: 100%;' v-model="it.number" controls-position="right" :min="1" :max="100"></el-input-number>
							  	</el-col>
							  	<el-col :span="6" class='inpNum' v-if='dex>0'>
							  		<el-button type="text" class='tx_tlue' @click='delItMatter(dex)'>删除</el-button>
							  	</el-col>
							</el-row>
					  		<div>
					  			<el-button type="text" class='tx_tlue' @click='addItMatter()'>+ 添加物料</el-button>
					  		</div>
					  	</div>
					</template>
					<template slot="yanshiTime" slot-scope="scope">
						<el-date-picker style='width: 100%;' v-model="htmlDatas[scope.current.keys]"   type="datetime"  placeholder="请选择" ></el-date-picker>
					</template>
					<template slot="signName" slot-scope="scope">
						<div class='signWarp' v-if="pupIsOpen&&type=='finishOrder'">
							<TSignName  ref="signBox" ></TSignName>
						</div>
					</template>
					
					
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 提交</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TSignName from '@/components/YTable/signName.vue';
	import pinyin from "js-pinyin";
	import {getPosition} from "@/api/business/order/tenant/order";
	import {getCompanyList,getProjectList} from "@/api/business/order/tenant/order";
	export default {
		props: {
			datas:{
				type:Object,
				default:()=>{
					return {
						orderId:'',
					}
				}
			},
			type:{
				type: String,
				default: 'reassignment',
			},
		},
		components: {TForm,TSignName},
		computed: {
		    pupIsOpen() {
		      	return this.$attrs.isOpen;
		    },
	  	},
		watch:{
			type:{
				deep: true,
				handler(n,o){
					this.resetTFrom();
				},
			},
			pupIsOpen:{
				deep: true,
				handler(n,o){
					if(n){
						console.log('弹窗展开了................')
						if(this.type=='finishOrder'){
							this.initPositionTree(this.datas.projectId);
						}
						
					}else{
						this.resetTFrom();
					}
				},
			},
		},
		data() {
			return {
				editPositionList: [],//空间位置数据
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[],
	  			popupTitle:'转派申请',
	  			materielArrs:[],//物料
	  			oConfig:{},//工单配置信息
	  			applyTypes:{
	  				//不同类型申请的内容
	  				reassignment:{ 
	  					name:'转派申请',
	  					httpUrl:'/order/tenant/orderTransfer/applyTransfer',
	  					method:'POST',
	  					formIts:[
	  						{name: '转派人',keys: 'recipient',value: '',type: 'employe',flex:12,isMust:true,tips:'单选',tipleft:'58px'},
	  						{name: '转派原因',keys: 'transferReason',value: '',type: 'input',flex:24,isMust:true},
	  					]
	  				},
	  				assist:{
	  					name:'协助申请',
	  					httpUrl:'/order/tenant/orderAssist/applyAssist',
	  					method:'POST',
	  					formIts:[
	  						{name: '协助人',keys: 'userList',value:[],type: 'employe',multiple:true,flex:12,isMust:true,
	  						tips:'可多选(最多5个)',tipleft:'58px',checkProps:{ multiple: true,value:'userId',label:'userName',children:'children',emitPath: false,},
	  						},
	  						{name: '协助原因',keys: 'assistReason',value: '',type: 'input',flex:24,isMust:true},
	  					]
	  				},
	  				drawMatter:{
	  					//给后台参数 格式 [{materielId: 2, materielName: "T5日光灯管", materielUnit: "个", number: "1"}]
	  					name:'领料申请',
	  					httpUrl:'/order/tenant/orderMateriel/applyMateriel',
	  					method:'POST',
	  					formIts:[
	  						{name: '选择物料',keys: 'sonList',value:[
	  							{materielId:'', materielName: "", materielUnit: "", number: 1}
	  						],type: 'slot',slotName: 'checkMatter',isMust:true},
	  					]
	  				},
	  				delayed:{
	  					//延时时间 格式 "2023-03-10 18:09"   且不能少于当前时间5分钟
	  					name:'延时申请',
	  					httpUrl:'/order/tenant/orderDelayed/applyDelayed',
	  					method:'POST',
	  					formIts:[
	  						{name: '转延时至',keys: 'delayedDate',value: '',type: 'slot',slotName:'yanshiTime',flex:12,isMust:true,pickerType:'datetime',format:'yyyy-MM-dd hh:mm:ss'},
	  						{name: '延时原因',keys: 'delayedReason',value: '',type: 'input',flex:24,isMust:true},
	  					]
	  				},
	  				closeOrder:{
	  					name:'关单申请',
	  					httpUrl:'/order/tenant/orderFill/closeOrder',
	  					method:'POST',
	  					formIts:[
	  						{name: '关单原因',keys: 'closeReason',value: '',type: 'input',flex:24,isMust:true},
	  						{name: '关单情况',keys: 'closeResource',flex:24,imgWidth:'80px',imgHeight:'80px',value:[
//		  						{fileId: 32127,fileType: "video/mp4",thumbnailId: 32126,},
		  					],type: 'imgVideo',allowAdd:true,},
	  					]
	  				},
	  				finishOrder:{
	  					name:'完成工单',
	  					httpUrl:'/order/tenant/orderFill/implementCompleteOrder',
	  					method:'POST',
	  					formIts:[
	  						{name: '完成说明',keys: 'completeSituation',value: '',type: 'input',flex:13,isMust:true,},
	  						{name: '所在位置',keys: 'positionId',value: '',type: 'cascader',options:[],flex:13,isMust:true,checkProps:{
	  							value:'positionId',label:'positionName',children:'children',checkStrictly:true,multiple:false,emitPath: false,
	  						}},
	  						{name: '现场签字',keys: 'webSign',value: 'A',type: 'slot',slotName: 'signName',flex:13,isMust:true,isHide:true},
	  						{name: '设备名称',keys: 'deviceId',value: '',type: 'devices',flex:13,changMethod:this.changeDevice},
	  						{name: '现场情况',keys: 'implementResource',flex:24,imgWidth:'80px',imgHeight:'80px',diyClass:'mgBottm',value:[
//		  							{fileId: 31720,fileType: "image/jpeg",thumbnailId: null,},
	  						],type: 'imgVideo',allowAdd:true,},
	  					]
	  				},
	  				
	  			},
	  			
	  			
			}
		},
		
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeDevice(val,it){
				//选择设备  把位置赋值到所在位置中
				for(let item of this.$store.state.app.allDeviceArrs){
					if(val==item.deviceId){
						this.htmlDatas.positionId=item.positionId;
						break;
					}
				}
			},
			async initPositionTree(projectId){
				if(this.type=='finishOrder'){
					let res=await this.ApiHttp('/base/tenant/position/selectPositionTree?projectId='+projectId);
					if(res){
						this.common.insertOptions(this.formlist,'positionId',res);//添加选项数据到所属项目
					}
				}
			},
			changeProject(val){
				//切换项目
				this.htmlDatas.positionId=null;
				this.getPosition(val);
			},
			// 联动企业名称
		    changeEnterpriseName(val) {
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.enterpriseCode=n;
		    },
			async resetTFrom(){
				this.initMaterielDatas();
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
				this.htmlDatas={};
//				let pData=JSON.parse(JSON.stringify(this.applyTypes[this.type]));
				let pData=this.applyTypes[this.type];
				this.formlist=pData.formIts;
				if(this.type=='finishOrder'){
	  				//如果是完成工单的时候  需要去获取工单配置  看此工单是否需要显示签名
	  				await this.getOrderConfig(this.datas.orderConfigId);
	  			}
				this.popupTitle=pData.name;
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		positionIdChange(val){
	  			console.log('val',val)
	  			this.$nextTick(() => {
	  				console.log('isOk',this.$refs.htmlFrom)
	  				this.$refs.htmlFrom.validate();
				})
	  			
	  		},
	  		async initMaterielDatas(){
	  			//获取物料数据
	  			if(this.type=='drawMatter'){
					if(!this.datas.projectId) return;
		  			let res = await this.ApiHttp('/base/tenant/materiel/app/manage/intelligentsearchcode?projectId='+this.datas.projectId);
		  			if(res){
		  				this.materielArrs=res;
		  			}
				}
	  		},
	  		delItMatter(dex){
	  			this.htmlDatas.sonList.splice(dex,1)
	  		},
	  		addItMatter(){
	  			this.htmlDatas.sonList.push({materielId:'', materielName: "", materielUnit: "", number: 1});
	  		},
			closeMypup(){
				this.$emit('close')
			},
			initProjectArrs(){
				//加载项目数据
				getProjectList({
						companyId: null
					}).then((res) => {
					res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);//添加选项数据到所属项目
				});
			},
			getPosition(proId) {
				getPosition(proId).then((res) => {
					let Arr1=[];
					res.map(it=>{
						if(it.positionId){
							Arr1.push(it)
						}
					})
					let data = listToTree(Arr1, "positionId", "parentId");
					this.recursivePosition(data);
				});
			},
			recursivePosition(row) {
				let data = row.map((v) => {
					v.label = v.positionName;
					v.id = v.positionId;
					if(v.children != undefined) {
						this.recursivePosition(v.children);
					}
					return v;
				});
				this.editPositionList = data;
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getOrderConfig(oid){
		    	//获取工单配置
		    	let res = await this.ApiHttp('/order/tenant/orderConfig/getConfigDetails',{
		    		isloading:false,
		    		orderConfigId:oid
		    	});
//		    	res.isAutograph='Y';
		    	this.oConfig={...res};
		    	for(let it of this.formlist){
		    		if(it.keys=='webSign'){
		    			it.isHide=(res.isAutograph=="N");
		    			break;
		    		}
		    	}
		    },
			async getFromDatas(){
				if(this.type=='drawMatter'){
					//校验领料
					let isAlow=true;
					for(let it of this.htmlDatas.sonList){
						if(!it.materielId){
							isAlow=false;
							break;
						}
					}
					if(!isAlow){
						this.$message('请选择物料');
						return;
					}
				}
				if(this.type=='delayed'){
					//校验延时
					let t1=new Date().getTime()+5*60*1000;
					let t2=new Date(this.htmlDatas.delayedDate).getTime();
					if(t2<t1){
						this.$message('不能少于当前时间5分钟');
						return;
					}
				}
				
				
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
					orderId:this.datas.orderId,
				}
				if(this.type=='assist'){
					if(params.userList.length>5){
						this.$message('最多选择5个~');
						return;
					}
					
				}
				if(this.type=='delayed'){
					if(params.delayedDate){
						let t=this.common.getTimeMmss(params.delayedDate);
						params.delayedDate=t.slice(0,t.length-3)
					}
					
				}
				
				let urls=this.applyTypes[this.type].httpUrl;
				if(this.type=='finishOrder'&&this.oConfig.isAutograph&&this.oConfig.isAutograph=='Y'){
					let res=await this.$refs.signBox.getSignResource();
					if(res){
						params.signResource=[res];
					}else{
						this.$message('请签字~');
						return;
					}
				}
//				console.log('From-Datas',JSON.parse(JSON.stringify(this.htmlDatas)),urls)
				delete params.webSign;//前端用的字段 不用给后台
				let res = await this.ApiHttp(urls,params,'post');
				if(res){
					this.$message({type:'success',message:'操作成功'});
					this.closeMypup();
					this.$emit('resetDetail');//刷新详情
				}
				
				
//				let urlType='insert'
//				if(this.dataId){
//					urlType='update';//编辑
//				}
//				params.enterpriseTag=params.enterpriseTag.join(',');
//				let res = await this.ApiHttp('/organization/enterprise/'+urlType,params,'post');
//				if(res){
//					this.$refs.htmlFrom.resetFields();
//					this.$emit('close','init')
//				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.inpNum{
	padding-left:8px;
}
.mtWarp{
	padding-top: 18px;
}
.itMtrow{
	padding:8px 0;
}
.tx_tlue{
	color:#1A4CEC;
}
.signWarp{
	height: 168px;
	padding-top: 24px;
}
</style>