<template>
	<!--工单信息-->
	<div >
		<div class="infoBox" >
			<div class="if_name">服务详情</div>
			<div class="if_form">
				<TForm  :ref="refFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist"  :rowGutter='16'>
					<template slot="paidItems" slot-scope="scope">
						<div class="_ngray">收费项目</div>
						<div class="pDetails">
							<div class="f_warp">
								<div class="fx1">项目</div>
								<div class="fx2">收费标准</div>
								<div class="fx3">数量</div>
							</div>
            	<div class="f_warp txg" v-for="(it,dex) in htmlDatas[scope.current.keys]" :key='dex'>
            		<div class="fx1">{{it.serviceContent}}</div>
            		<div class="fx2">{{it.price}} 元/{{it.serviceUnit}}</div>
            		<div class="fx3">{{it.num}}</div>
            	</div>
            </div>
					</template>
				</TForm>
			</div>
		</div>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			datas:{
				type:Object,
				default:()=>{
					return {
						
						orderTypes:'',
						inPlaceStr:'',
						settlementType:'',
						totalAmount:'',
						paidServiceClientVOS:[
							{num:1,serviceContent:'更换门把手',price:15,serviceUnit:'把'},
						],
						
					}
				}
				
			},
		},
		components: {TForm},
		data() {
			return {
				refFrom:'padiFrom',
				htmlDatas:{
//					orderTypes:'有偿工单',
//					inPlaceStr:'深圳市美丽世界有限公司',
//					settlementType:'月结',
//					totalAmount:'60.00',
//					paidServiceClientVOS:[
//						{num:1,serviceContent:'更换门把手',price:15,serviceUnit:'把'},
//					],
				},
  			formlist:[
  				{name: '工单类型',keys: 'orderTypes',value: '有偿工单',type: 'txRow',flex:6,},
  				{name: '维修位置 ',keys: 'inPlaceStr',value: '',type: 'txRow',flex:18,},
  				{name: '结算类型',keys: 'settlementType',value: '',type: 'txRow',flex:6,},
  				{name: '服务金额（元） ',keys: 'totalAmount',value: '',type: 'txRow',flex:18,},
  				{name: '收费项目',keys: 'paidServiceClientVOS',value: '',type: 'slot',diyClass:'diyItems',labelHide:true,slotName:'paidItems',flex:6,},
  			],
			}
		},
		
		computed: {
	  	},
		watch:{
			datas: {
				deep: true, // 深度监听
				handler(val, oval) {
//					this.resetTFrom();
					this.resetTFrom();
				}
			},
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			resetTFrom(){
				if(this.$refs[this.refFrom]) this.$refs[this.refFrom].resetFields();
  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
  			this.resetHtmlData();
  		},
  		resetHtmlData(){
				if(this.datas){
					this.htmlDatas={...this.htmlDatas,...this.datas};
					this.htmlDatas.settlementType=this.htmlDatas.settlementType=='1'?'现结':'月结';
				}
			},
			closeMypup(){
				this.$emit('close')
			},
		},

	}
</script>

<style lang="scss" scoped>
.infoBox{
	background: #F8F8F8;
	padding: 12px 32px;
	margin: 16px 0;
	border-radius: 4px;
	.if_name{
		padding: 12px 0;
		color: #2F3941;
		font-size: 16px;
		margin-top: 8px;
		font-weight: bold;
	}
	._ngray{
		font-size: 12px;
    color: #909399;
	}
	.pDetails{
		min-width: 298px;
		padding:12px 24px;
		background: #EEEEEE;
		
		.txg{
				color: #909399;
			}
		.f_warp{
			display: flex;
			.fx1{
				width: 30%;
			}
			.fx2{
				width: 50%;
			}
			.fx3{
				width: 20%;
			}
		}
	}
	
}
</style>
<style lang="scss">
</style>