var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "infoBox" }, [
      _c("div", { staticClass: "if_name" }, [_vm._v("服务详情")]),
      _c(
        "div",
        { staticClass: "if_form" },
        [
          _c("TForm", {
            ref: _vm.refFrom,
            attrs: {
              autoCheck: true,
              model: _vm.htmlDatas,
              formlist: _vm.formlist,
              rowGutter: 16,
            },
            scopedSlots: _vm._u([
              {
                key: "paidItems",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "_ngray" }, [_vm._v("收费项目")]),
                    _c(
                      "div",
                      { staticClass: "pDetails" },
                      [
                        _c("div", { staticClass: "f_warp" }, [
                          _c("div", { staticClass: "fx1" }, [_vm._v("项目")]),
                          _c("div", { staticClass: "fx2" }, [
                            _vm._v("收费标准"),
                          ]),
                          _c("div", { staticClass: "fx3" }, [_vm._v("数量")]),
                        ]),
                        _vm._l(
                          _vm.htmlDatas[scope.current.keys],
                          function (it, dex) {
                            return _c(
                              "div",
                              { key: dex, staticClass: "f_warp txg" },
                              [
                                _c("div", { staticClass: "fx1" }, [
                                  _vm._v(_vm._s(it.serviceContent)),
                                ]),
                                _c("div", { staticClass: "fx2" }, [
                                  _vm._v(
                                    _vm._s(it.price) +
                                      " 元/" +
                                      _vm._s(it.serviceUnit)
                                  ),
                                ]),
                                _c("div", { staticClass: "fx3" }, [
                                  _vm._v(_vm._s(it.num)),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }