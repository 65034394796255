<template>
	<!--工单信息-->
	<div>
		<div class="infoBox" v-if="formlist.length > 0">
			<div class="if_name">{{ formTitle }} <el-button type="text" @click='editOrder' v-if='isEdit'
					class='editBtn txBlue'> <i class="el-icon-edit-outline"></i>修改工单</el-button></div>
			<div class="if_form">
				<TForm :ref="refFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16'>
					<template slot="orderCode" slot-scope="scope">
						<div class="trCode">
							<div class="_name">工单编号 </div>
							<div class="_val"> <span id="copycode">{{ htmlDatas[scope.current.keys] }}</span><el-button
									type="text" @click='copyCode' class='txBlue'> <i
										class="el-icon-copy-document"></i>复制</el-button> </div>
						</div>
					</template>
					<template slot="txDesc" slot-scope="scope">
						<div class="trCode">
							<div class="_name">工单描述 </div>
							<div class="_val ">
								<div v-if="!htmlDatas[scope.current.keys]">'-'</div>
								<div v-else>
									<div class="txblod" v-for="(tx,dx) in htmlDatas[scope.current.keys].split('\n')" :key='dx'>{{tx}}</div>
								</div>
							</div>
						</div>
					</template>
					
					<template slot="jumpUrl" slot-scope="scope">
						<div class="trCode">
							<div class="_name">{{ scope.current.name }} </div>
							<div class="_val">
								<el-button type="text" @click='toNewPage(htmlDatas[scope.current.keys])' class='txBlue'>
									{{ htmlDatas[scope.current.keys] }}</el-button>
							</div>
						</div>
					</template>
				</TForm>
			</div>
		</div>
	</div>
</template>
<script>
import TForm from '@/components/YTable/TForm.vue';
export default {
	props: {
		type: {
			type: String,
			default: '1',
		},
		dataId: {
			type: String,
			default: '',
		},
		datas: {
			type: Object,
			default: () => {
				return {
					orderImp: {}
				}
			}

		},
	},
	components: { TForm },
	data() {
		return {
			refFrom: 'htmlFrom',
			htmlDatas: {},
			formlist: [],
			formTitle: '工单信息',
			isEdit: false,
			htmlFromBytype: [
				{ name: '工单信息', val: '1', initFunc: this.initOrderEditForm },
				{ name: '执行详情', val: '2', initFunc: this.initExecuteingForm },
				{ name: '申请详情', val: '3', initFunc: this.initApplyDetailForm },
				{ name: '用户评价', val: '4', initFunc: this.initUserEvalueForm },
			],
			resourceArrs2: [
				{
					fileId: 31720,
					fileType: "image/jpeg",
					thumbnailId: null,
				},
				{
					fileId: 31721,
					fileType: "video/mp4",
					thumbnailId: 31718,
				},

			],
			typeArr:[
				{'name':'动漫','val':'customer'},
				{'name':'锦绣','val':'customergs'},
				{'name':'金威','val':'customerjw'},
			],
		}
	},

	computed: {
	},
	watch: {
		dataId: {
			deep: true, // 深度监听
			handler(val, oval) { }
		},
		datas: {
			deep: true, // 深度监听
			handler(val, oval) {
				this.initHtmlView();

			}
		},
	},
	created() {
		let t = new Date().getTime();
		this.refFrom += String(t);
		this.initHtmlView();
	},
	mounted() { },
	updated() { },
	beforeDestroy() { },
	methods: {
		async initHtmlView() {
			for (let it of this.htmlFromBytype) {
				if (it.val == this.type) {
					this.formTitle = it.name;
					await it.initFunc();
					break;
				}
			}
			this.isAllowEdit();
			this.resetTFrom();
		},
		async isAllowEdit() {
			this.isEdit = false;
			if (this.type !== '1' || !this.datas.orderId) {
				return;
			}
			let params = {
				orderId: this.datas.orderId,
				isloading: false,
			}
			let res = await this.ApiHttp('/order/tenant/orderFill/orderModifyPermission', params, 'get');
			if (res) {
				this.isEdit = true;
			}
		},
		async initOrderEditForm() {
			//工单详情
			let orderArr = [
				{ name: '报单部门', keys: 'submitUserDeptName', value: '', type: 'txRow', flex: 6, },
				{ name: '报单人员', keys: 'submitUserNamePhone', value: '', type: 'txRow', flex: 18, },
				{ name: '报单时间', keys: 'creationDateStr', value: '', type: 'txRow', flex: 6, },
				{ name: '工单级别', keys: 'orderLevelText', value: '', type: 'txRow', flex: 18, },
				{ name: '所属项目', keys: 'projectName', value: '', type: 'txRow', flex: 6, },
				{ name: '工单类型', keys: 'orderTypeStr', value: '', type: 'txRow', flex: 18, },
				{ name: '设备位置', keys: 'location', value: '', type: 'txRow', flex: 6, },
				{ name: '设备名称', keys: 'deviceName', value: '', type: 'txRow', flex: 18, },
				{ name: '工单模版', keys: 'configName', value: '', type: 'txRow', flex: 6, },
				{ name: '工单编号', keys: 'orderCode', value: '', type: 'slot', slotName: 'orderCode', diyClass: 'diyCode', labelHide: true, flex: 18, },
				{ name: '工单描述', keys: 'contentDesc', value: '', type: 'slot', slotName: 'txDesc',flex: 24, diyClass: 'diyCode', labelHide: true, },
				{
					name: '现场情况图片/视频', keys: 'subResource', flex: 24, imgWidth: '80px', diyClass: 'diyImgs', imgHeight: '80px', value: [
						//		  					{fileId: 31720,fileType: "image/jpeg",thumbnailId: null,},
					], type: 'imgVideo', allowAdd: false,
				},
			]
			this.formlist = orderArr;

		},
		toNewPage(urls) {
			let params = {
				path: urls,
			};
			console.log(urls);
			this.$router.push(params);
		},

		async initExecuteingForm() {
			//执行详情
			let executeArr = [
				{ name: '责任部门', keys: 'resDeptName', value: '', type: 'txRow', flex: 6, },
				{ name: '部门负责人', keys: 'resDeptLeadName', value: '', type: 'txRow', flex: 18, },
				{ name: '工单执行人', keys: 'handlePersonNamePhone', value: '', type: 'txRow', flex: 6, },
				{ name: '开始执行时间', keys: 'startDateStr', value: '', type: 'txRow', flex: 18, },
				{ name: '提交验收时间', keys: 'sumitAcceptTime', value: '', type: 'txRow', flex: 6, },
				{ name: '现场签到地址', keys: 'signAddress', value: '', type: 'txRow', flex: 18, },
				{ name: '完成说明', keys: 'completeSituation', value: '', type: 'txRow', flex: 24, },
				{ name: '现场签字', keys: 'signResource', flex: 24, imgWidth: '80px', imgHeight: '80px', value: [], type: 'imgVideo', allowAdd: false, },
				{ name: '完成情况图片/视频', keys: 'implementResource', flex: 24, imgWidth: '80px', imgHeight: '80px', value: [], type: 'imgVideo', allowAdd: false, },
				{ name: '关单原因', keys: 'closeReason', value: '', type: 'txRow', flex: 18, },
				{ name: '关单情况图片/视频', keys: 'closeResource', flex: 24, imgWidth: '80px', imgHeight: '80px', value: [], type: 'imgVideo', allowAdd: false, },
			]
			this.formlist = executeArr;

		},

		async initApplyDetailForm() {
			//设置 申请详情表单
			let params = {
				isloading: false,
				orderId: this.datas.orderId,
				tenantId: this.$store.getters.currentTenant,
			}
			if (!params.orderId||!params.tenantId) return;
			let dataUrl=this.typeArr[+params.tenantId-1].val;
			let res = await this.ApiHttp(`/${dataUrl}/wx/order/queryOrderDetail`, params);
			if (res.detail) {
				let newForm = [];
				res.detail.map((it, dex) => {
					if (it.type == 'text' && it.value) {
						it.flex = 12;
						it.value = String(it.value);
						it.type = 'txRow'
						newForm.push(it)
					}
					if (it.type == 'imgVedios') {
						it.value = it.value || [];
						let imgItem = {
							name: it.name || '图片/视频',
							keys: it.keys,
							value: it.value,
							flex: 24,
							imgWidth: '80px',
							imgHeight: '80px',
							type: 'imgVideo'
						};
						newForm.push(imgItem)
					}
					if (it.type == 'jumpUrl') {
						it.flex = 12;
						it.value = String(it.value);
						it.type = 'slot';
						it.slotName = 'jumpUrl';
						it.diyClass ='diyCode',
						it.labelHide = true
						newForm.push(it)
					}
				})
				this.formlist = newForm;
			}
		},
		async initUserEvalueForm() {
			//设置 用户评价表单
			let params = {
				isloading: false,
				orderId: this.datas.orderId,
				tenantId: this.$store.getters.currentTenant,
			}
			if (!params.orderId||!params.tenantId) return;
			let dataUrl=this.typeArr[+params.tenantId-1].val;
			let res = await this.ApiHttp(`/${dataUrl}/wx/order/queryGradeDetail`, params);
			if (res) {
				let newForm = [];
				res.map((it, dex) => {
					if (it.type == 'text' && it.value) {
						it.type = 'txRow'
						it.flex = 12;
						it.value = String(it.value);
						newForm.push(it)
					}
					if (it.type == 'imgVedios') {
						it.value = it.value || [];
						let imgItem = {
							name: it.name || '图片/视频',
							keys: it.keys,
							value: it.value,
							flex: 24,
							imgWidth: '80px',
							imgHeight: '80px',
							type: 'imgVideo'
						};
						newForm.push(imgItem)
					}
				})
				console.log('newForm', newForm)

				this.formlist = newForm;
			}
		},
		editOrder() {
			this.$store.commit('setOrderAddEdit', {
				open: true,
				type: 'edit',
				dataId: this.datas.orderId,
			});
		},
		getTitle() {
			let arr = ['工单信息', '申请详情', '执行详情', '用户评价']
			return arr[(+this.type) - 1]

		},
		copyCode() {
			const range = document.createRange();
			range.selectNode(document.getElementById('copycode'));
			const selection = window.getSelection();
			if (selection.rangeCount > 0) selection.removeAllRanges();
			selection.addRange(range);
			document.execCommand('copy');
			selection.removeRange(range);
			this.$message({ type: 'success', message: '复制成功' });
		},
		resetTFrom() {
			if (this.$refs[this.refFrom]) this.$refs[this.refFrom].resetFields();
			for (let item of this.formlist) { //初始化赋值我的表单数据
				this.$set(this.htmlDatas, item.keys, item.value);
			}
			let orderDatas = JSON.parse(JSON.stringify(this.datas));
			let addKyes = {};
			if (orderDatas.orderImp) {
				addKyes = {
					//前端添加的需要用到的字段
					completeSituation: orderDatas.orderImp.completeSituation || '',//完成说明
					sumitAcceptTime: orderDatas.orderImp.lastUpdateDateStr || '',//提交验收时间
					implementResource: orderDatas.orderImp.implementResource || '',//提交验收时间
				};

			}
			if (orderDatas.orderSign) {
				addKyes.signAddress = orderDatas.orderSign.detailAddress;//签到地址
			}
			//	  			for(let k2 in addKyes) { 
			//					this.$set(this.htmlDatas, k2, addKyes[k2]);
			//				}
			this.htmlDatas = { ...this.htmlDatas, ...this.datas, ...addKyes };

			if (this.datas.orderStatus !== 9) {
				//若工单不是已经关单状态不显示
				this.htmlDatas.closeReason = '';
				this.htmlDatas.closeResource = [];
			}
		},
		closeMypup() {
			this.$emit('close')
		},
	},

}
</script>

<style lang="scss" scoped>
.infoBox {
	background: #F8F8F8;
	padding: 12px 32px;
	margin: 16px 0;
	border-radius: 4px;

	.if_name {
		padding: 12px 0;
		color: #2F3941;
		font-size: 16px;
		margin-top: 8px;
		font-weight: bold;
	}

	.if_form .trCode {
		padding: 12px 0;

		._name {
			font-size: 12px;
			color: #909399;
			font-weight: inherit;
			line-height: 20px;
		}

		._val {
			font-size: 14px;
			color: #2F3941;
			font-weight: bold;
			line-height: 28px;

			.el-button {
				padding: 0;
			}
			.txblod{
			    font-size: 14px;
			    color: #2F3941;
			    font-weight: bold;
			}
		}
	}

	.editBtn {
		margin-left: 10px;
	}

	.txBlue i {
		margin-left: 6px;
		margin-right: 2px;
	}
}
</style>
<style lang="scss">
.infoBox {
	.if_form .t_row {
		margin: 12px 0;

		.tr_name {
			font-size: 12px;
			color: #909399;
			font-weight: inherit;
		}
	}

	.diyCode {
		.el-form-item {
			margin-bottom: 0 !important;
		}
	}
}</style>